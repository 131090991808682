<script setup>
    const props = defineProps(['src', 'name']);
    const initials = computed(() => props.name?.split(' ').map(it => it.charAt(0)).join('') ?? '');
    const initialCode = initials.value.split('').reduce((acc, item) => acc += item.charCodeAt(0) * 1.2, 0);

    
    function createThumb(e) {
        if (navigator.vendor.indexOf('Apple') == -1) return;

        const canvas = document.createElement('canvas');
        canvas.height = canvas.width;
        const ctx = canvas.getContext("2d");
        

        ctx.font = "92px Play";
        ctx.textAlign = "center";
        ctx.textBaseline = 'middle';
        ctx.fillStyle = `#fff`;
        ctx.strokeStyle = `hsl(${ initialCode % 360}deg, 100%, 40%)`;
        ctx.lineWidth = 3;

        ctx.fillText(initials.value, canvas.width / 2, canvas.height / 2);
        ctx.strokeText(initials.value, canvas.width / 2, canvas.height / 2);

        e.target.src = canvas.toDataURL();
    }
</script>

<template>
    <img class="userpic uppercase w-auto rounded-full object-cover aspect-square flex-shrink-0 border-[0.5px] text-white font-semibold" :src="src" :alt="initials" @error="createThumb" :style="`background: linear-gradient(0deg, hsl(${ initialCode % 360}deg, 50%, 50%), hsl(${ initialCode % 360}deg, 50%, 80%));`" />
</template>

<style scoped>
    img{
        container-type: inline-size;
        font-size: 0;
    }
    img[alt]:after{
        display: grid;
        place-items: center;
        background: inherit;
        line-height: 1;
        text-align: center;
        font-size: 36cqw;
    }
</style>
